import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  InMemoryCache
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import * as Sentry from "@sentry/react";
import React from "react";

const cmsLink = new BatchHttpLink({
  uri: (import.meta.env.VITE_CMS_API_URL || "").replace(/\/$/, "") + "/graphql"
});

const railsApiLink = new BatchHttpLink({
  uri:
    (import.meta.env.VITE_PLATFORM_API_URL || "").replace(/\/$/, "") +
    "/graphql"
});

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  Sentry.withScope((scope) => {
    scope.setTransactionName(operation.operationName);
    scope.setContext("apolloGraphQLOperation", {
      operationName: operation.operationName,
      variables: operation.variables,
      extensions: operation.extensions
    });

    // report any graphql errors
    (graphQLErrors || [])
      .filter(
        // skip expected errors (e.g validation errors)
        (error) => !["BAD_REQUEST"].includes(error.extensions?.code as string)
      )
      .forEach((error) => {
        Sentry.captureMessage(error.message, {
          level: "error",
          fingerprint: ["{{ default }}", "{{ transaction }}"],
          contexts: {
            apolloGraphQLError: {
              error,
              message: error.message,
              extensions: error.extensions
            }
          }
        });

        if (
          error.extensions?.code === "INVALID_AUTHORIZATION" &&
          window.location.pathname !== "/unauthorized"
        ) {
          window.location.href = "/unauthorized?error=invalid_authorization";
        }
      });

    // just log network errors, these are automatically retried
    if (networkError) {
      console.error("networkError", networkError);
    }
  });
});

const retryLink = new RetryLink();

const createApolloClient = (accessToken: string | null) => {
  const railsApiAuthMiddleware = setContext((_request, previousContext) => {
    if (!accessToken) return previousContext;

    return Object.assign(previousContext, {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : ""
      }
    });
  });

  return new ApolloClient({
    link: ApolloLink.split(
      // order matters here -- match first, then second if above is true
      (operation) => operation.getContext().clientName === "rails-api",
      // rails-api
      from([retryLink, errorLink, railsApiAuthMiddleware, railsApiLink]),
      // strapi-cms
      from([retryLink, errorLink, cmsLink])
    ),
    cache: new InMemoryCache(),
    connectToDevTools: import.meta.env.NODE_ENV !== "production"
  });
};

const GraphqlProvider: React.FC<{
  client: ApolloClient<unknown>;
  children: React.ReactNode;
}> = ({ client, children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

export { createApolloClient, GraphqlProvider };
